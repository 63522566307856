import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import VisaStamp from "../images/visa-stamp.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "inav-traveldests.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div id="#about">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="ABOUT US" />
            <h2 className="font-display md:text-display-xl text-display-md font-normal pb-4">
              We turn your <span className="italic">dream trip</span>{" "}
              into reality
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              With over 15 years experience in the travel & tourism industry, Ineedavisa.PH has unparalleled expertise to help you
              get the visa that you need.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
            Whether your travel plans include destinations such as the United States, Canada, Australia, the EU, Japan, or Korea, among others, 
            our top-tier visa consulting service ensures your compliance with all documentary requirements for entry into these countries.
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Visa destinations"
            />
            <img
              src={VisaStamp}
              alt="Visa Stamp"
              className="absolute left-[42%] -top-14"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
